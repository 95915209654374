/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useTranslation } from 'react-i18next';


import Header from "./header"
import "./layout.css"
import "./normalize.css"
import "./hamburgers.css"


const Layout = ({ children, location }) => {
  const { t } = useTranslation();

  const { strapiGlobal, site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      strapiGlobal {
        logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width:280)
              }
            }
          }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


  const { logo } = strapiGlobal;
  return (
    <>
      <main className="mymain">
        <Header siteTitle={site.siteMetadata?.title || `Title`} location={location} />
        <div>
          {children}
        </div>
        <footer>
          <div className="c-block c-block--footer">
            <div className="site-width">
              <div className="c-block__content c-block__content--footer">
                <div className="footer__column footer__column--social">
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/autohunt1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/auto.hunt/"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCLgWdt4xQq3XHC3Hy4HZJFQ?"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-youtube"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon></svg></a>
                </div>
                <div className="footer__column">
                  <span className="footer__column-title">Autohunt</span>
                  <div>
                    <p>Bouwke 17, B-8870 Ingelmunster</p>
                    <p>+32 472 37 99 80</p>
                    <p>axel@autohunt.be</p>
                  </div>
                </div>
                <div className="footer__column">
                  <ul className="footer-links">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/cars">Wagens</Link></li>
                    <li><Link to="/about-us">Over ons</Link></li>
                    <li><Link to="/sell-your-car">Verkoop je wagen</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                  </ul>
                </div>
                <div className="footer__column footer__column--logo">
                  <Link to="/" >
                    <GatsbyImage
                      image={logo.localFile.childImageSharp.gatsbyImageData}
                      width={100}
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="privacy">© {new Date().getFullYear()} Autohunt - <Link style={{color: "white"}} to="/terms-and-conditions" >{t('TermsAndConditionsLabel')}</Link></div>
        </footer>
      </main>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
