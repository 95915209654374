import * as React from "react"
import PropTypes from "prop-types"
import { NavLink, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import { Link, Trans, useI18next } from 'gatsby-plugin-react-i18next';

const Header = ({ siteTitle, location }) => {
  const { languages, originalPath } = useI18next();

  const { strapiGlobal } = useStaticQuery(
    graphql`
      query {
        strapiGlobal {
          siteName
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width:180)
              }
            }
          }
        }
      }
    `
  )

  const { logo } = strapiGlobal;
  return (
    <header
      style={{
        backgroundColor: `#FFF`,
        position: (location && location.path == '/') ? 'absolute' : 'relative',
        zIndex: 9,
        width: '100%'
      }}
    >
      <div
        className="header-container site-width"
        style={{
          margin: `0 auto`,
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Link
          to="/"
        >
          {/* <GatsbyImage
            image={logo.localFile.childImageSharp.gatsbyImageData}
            // width={100}
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Logo"
          /> */}
          <StaticImage
            style={{ width: '180px' }}
            src="../images/logo-autohunt.png" alt="Logo" />
        </Link>

        <button id="mobile-nav" class="hamburger hamburger--collapse" type="button" onClick={
          (e) => {
            let hamburger = document.getElementById('mobile-nav');
            hamburger.classList.toggle('is-active');
            let nav = document.getElementsByClassName('main-nav-mobile')[0];
            nav.classList.toggle('is-open');
          }
        }>
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>

        <nav class="main-nav">
          <ul>
            <li><Link activeClassName="active" className="main-nav__link" to="/cars"><Trans>Cars</Trans></Link></li>
            <li><Link activeClassName="active" className="main-nav__link" to="/sold"><Trans>Sold</Trans></Link></li>
            <li><Link activeClassName="active" className="main-nav__link" to="/about-us"><Trans>AboutUs</Trans></Link></li>
            <li><Link activeClassName="active" className="main-nav__link" to="/sell-your-car"><Trans>SellYourCar</Trans></Link></li>
            <li><Link activeClassName="active" className="main-nav__link" to="/contact"><Trans>Contact</Trans></Link></li>
            <li>
              <div className="main-nav__languages">
                {languages.map((lng) => (
                  <div class="language-link">
                    <Link to={originalPath} language={lng}>{lng}</Link>
                  </div>
                ))}
              </div>
            </li>
          </ul>
        </nav>
      </div>
      <nav class="main-nav-mobile">
        <div className="main-nav-mobile__inner">
          <ul>
            <li class="mobile-nav-item"><Link to="/cars"><Trans>Cars</Trans></Link></li>
            <li class="mobile-nav-item"><Link to="/sold"><Trans>Sold</Trans></Link></li>
            <li class="mobile-nav-item"><Link to="/about-us"><Trans>AboutUs</Trans></Link></li>
            <li class="mobile-nav-item"><Link to="/sell-your-car"><Trans>SellYourCar</Trans></Link></li>
            <li class="mobile-nav-item"><Link to="/contact"><Trans>Contact</Trans></Link></li>
            <li class="mobile-nav-item">
              <div className="main-nav__languages" style={{ display: 'flex', justifyContent: 'center' }}>
                {languages.map((lng) => (
                  <div class="language-link">
                    <Link to={originalPath} language={lng}>{lng}</Link>
                  </div>
                ))}
              </div>
            </li>
          </ul>
          <a href="callto:+32472379980" class="btn main-cta btn--icon" style={{ marginBottom: '32px' }}>
            <svg style={{ marginLeft: '0px', marginRight: '16px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
            +32 472 37 99 80
          </a>
          <a className="googlemaps-link" href="https://goo.gl/maps/T9KwicqrzuhjBkbZ9" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <svg style={{ marginRight: '8px' }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
            Bouwke 17, Ingelmunster
          </a>
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
